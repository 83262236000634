section{
    margin:                     0 -$grid-gutter-width/2;

    &.v-padding{
        padding:                6em 0;
    }

    &.section-intro{
        background-color:       $brand-primary;
        color:                  $white;
    }

    &:nth-child(2){
        background-color:       $white;
    }

    &:nth-child(3){
        background-color:       $light-gray;
    }

    &:nth-child(4){
        background-color:       $dark-gray;
        color:                  $white;
        .gallery{
            .gallery-chevron{
                border-color:           $white;
                color:                  $white;
            }
            .mobile-controller{
                li{
                    a{
                        border-color: $white;
                        color: $white;
                        border-radius: $grid-gutter-width;
                        transition: all 0.3s;
                        font-weight: bold;
                        background-color: transparent;
                        &:hover{
                            color: $brand-primary;
                            font-weight: bold;
                            background-color: $white;
                            transition: all 0.3s;
                        }
                    }
                }
            }
        }
    }

    &.section-newsletter{
        background-color:       $brand-primary;
        color:                  $white;

        label.gfield_label{
            display:                none;
            visibility:             hidden;
        }

        .validation_error{
            font-size:              16px;
            padding:                $grid-gutter-width;
            background-color:       rgba(255,255,255,0.6);
            color:                  $brand-primary;
        }
        .validation_message{
            font-size:              16px;
            padding:                $grid-gutter-width;
            background-color:       rgba(255,255,255,0.3);
            color:                  $white;
        }

        .gform_body{
            float:                  left;
            width:                  75%;
            ul{
                list-style-type:        none;
                padding:                0;
            }
            input{
                width:                  100%;
                padding:                0.7em;
                border:                 2px solid $white;
                background:             none;
                color:                  $white;
                font-size:              18px;

                &::-webkit-input-placeholder {
                    color:                  $white;
                }
                &:-moz-placeholder {
                    color:                  $white;
                    opacity:                1;
                }
                &::-moz-placeholder {
                    color:                  $white;
                    opacity:                1;
                }
                &:-ms-input-placeholder {
                    color:                  $white;
                }
                &:placeholder-shown {
                    color:                  $white;
                }
            }
        }
        .gform_footer{
            float:                  left;
            width:                  24.9%;
            input{
                width:                  100%;
                padding:                0.7em;
                font-size:              18px;
                border:                 2px solid $white;
                background-color:       rgba(255,255,255,0.1);
                color:                  $white;
                border-left:            none;
                transition:             all 0.3s;
                &:hover{
                    background-color:       $white;
                    color:                  $brand-primary;
                    transition:             all 0.3s;
                }
            }
        }
    }

    .mymail-email-wrapper{
        float:                  left;
        width:                  75%;
        ul{
            list-style-type:        none;
            padding:                0;
        }
        input{
            width:                  100%;
            padding:                0.7em;
            border:                 2px solid $white;
            background:             none;
            color:                  $white;
            font-size:              18px;

            &::-webkit-input-placeholder {
                color:                  $white;
            }
            &:-moz-placeholder {
                color:                  $white;
                opacity:                1;
            }
            &::-moz-placeholder {
                color:                  $white;
                opacity:                1;
            }
            &:-ms-input-placeholder {
                color:                  $white;
            }
            &:placeholder-shown {
                color:                  $white;
            }
        }
        &.error{
            input{
                width:                  100%;
                padding:                0.7em;
                border:                 2px solid $white;
                background:             none;
                color:                  $white;
                font-size:              18px;

                &::-webkit-input-placeholder {
                    color:                  $white;
                }
                &:-moz-placeholder {
                    color:                  $white;
                    opacity:                1;
                }
                &::-moz-placeholder {
                    color:                  $white;
                    opacity:                1;
                }
                &:-ms-input-placeholder {
                    color:                  $white;
                }
                &:placeholder-shown {
                    color:                  $white;
                }
            }
        }
    }
    .mymail-submit-wrapper{
        float:                  left;
        width:                  24.9%;

        input.submit-button{
            margin-top: 0;
            width:                  100%;
            padding:                0.7em;
            font-size:              18px;
            border:                 2px solid $white;
            background-color:       rgba(255,255,255,0.1);
            color:                  $white;
            border-left:            none;
            transition:             all 0.3s;
            &:hover{
                background-color:       $white;
                color:                  $brand-primary;
                transition:             all 0.3s;
            }
        }
    }
    .mymail-form-info{
        font-size:              16px !important;
        padding:                $grid-gutter-width !important;
        background-color:       rgba(255,255,255,0.3) !important;
        color:                  $white !important;
        margin: 0 !important;
        border-radius: 0 !important;
        p{
            padding: 0;
            margin: 0;
        }
        &.error{
            background-color:       rgba(255,255,255,0.6) !important;
            color:                  $brand-primary !important;
        }
    }
    .gallery{
        .gallery-item{
            height:                 230px;
            position:               relative;
            display:                block;
            width:                  100%;
            overflow:               hidden;
            margin-bottom:          $grid-gutter-width;
            border:                 1px solid $white;

            img{
                position:               absolute;
                top:                    0;
                left:                   0;
                right:                  0;
                min-width:              100%;
                min-height:             230px;
                vertical-align:         middle;
                transform:              scale3d(1,1,1);
                transition:             all 0.3s;

            }

            &:before{
                content:                '\e140';
                font-family:            'Glyphicons Halflings';
                position:               absolute;
                margin-top:             -10px;
                text-align:             center;
                width:                  100%;
                color:                  $white;
                display:                block;
                top:                    50%;
                z-index:                1;
                opacity:                0;
                transform:              scale3d(2,2,2);
                transition:             all 0.3s;
            }

            &:after{
                content:                '';
                position:               absolute;
                top:                    0;
                left:                   0;
                right:                  0;
                bottom:                 0;
                color:                  $white;
                text-align:             center;
                display:                table-cell;
                height:                 100%;
                vertical-align:         middle;
                opacity:                0;
                transition:             all 0.3s;
            }

            &:hover{
                img{
                    transform:              scale3d(1.1,1.1,1.1);
                    transition:             all 1s;
                }

                &:before{
                    transform:              scale3d(1,1,1);
                    opacity:                0.4;
                    transition:             all 0.3s;
                }
                &:after{                 
                    box-shadow:             0 0 0 20px rgba(255,255,255,0.4) inset;
                    opacity:                1;
                    background-color:       rgba(102, 4, 4, 0.2);
                    transition:             all 0.3s;
                }
            }
        }

        &.carousel-3d {
            .carousel-inner {
                .item {
                    transition:             all 0.3s;
                    opacity:                1;
                }

                .item,
                .active.left,
                .active.right {
                    transform:              scale3d(1,1,1);
                    opacity:                0;
                }

                .active,
                .next.left,
                .prev.right {
                    transform:              scale3d(1,1,1);
                    opacity:                1;
                }

                .next,
                .prev,
                .active.left,
                .active.right {
                    transform:              scale3d(0.6,0.6,0.6);
                    left:                   0;
                    perspective:            600px;
                    transition:             all 0.3s;
                }
            }

            .carousel-control {
                z-index:                2;
            }
        }

        .carousel-control{
            opacity:                1;
            top:                    50%;
            margin-top:             -50px;
            background-image:       none;

            &.left{
                margin-left:            50px;
                float:                  left;
            }
            &.right{
                margin-right:           50px;
                float:                  right;
            }

        }

        .gallery-chevron{
            display:                table;
            margin:                 0 auto;
            width:                  100px;
            height:                 100px;
            border:                 2px solid;
            border-color:           $brand-primary;
            color:                  $brand-primary;
            border-radius:          100px;
            position:               relative;
            transition:             all 0.3s;

            &:before{
                border-color:           $brand-primary;
                content:                '';
                display:                block;
                position:               absolute;
                top:                    33px;
                width:                  30px;
                height:                 30px;
                border-color:           $brand-primary;
                transition:             all 0.3s;
            }

            &.left{
                &:before{
                    transform:              rotate(45deg) translate(0,0);
                    left:                   38px;
                    border-left:            2px solid;
                    border-bottom:          2px solid;
                }
            }
            &.right{
                &:before{
                    transform:              rotate(-135deg) translate(0,0);
                    left:                   28px;
                    border-left:            2px solid;
                    border-bottom:          2px solid;
                }
            }

            &:hover{
                &.right{
                    &:before{
                        transform:              rotate(-135deg) translate(-10px, 10px);
                        transition:             all 0.2s;
                    }
                }
                &.left{
                    &:before{
                        transform:              rotate(45deg) translate(-10px,10px);
                        transition:             all 0.2s;
                    }
                }
            }
        }
        .mobile-controller{
            clear: both;
            display: table; 
            margin: 0 auto;
            margin-top: $grid-gutter-width;
            li{
                a{
                    border-width: 2px;
                    border-color: $brand-primary;
                    border-radius: $grid-gutter-width;
                    transition: all 0.3s;
                    font-weight: bold;
                    background-color: transparent;
                    &:hover{
                        color: $white;
                        font-weight: bold;
                        background-color: $brand-primary;
                        transition: all 0.3s;
                    }
                }
            }
        }
    }
}


@media(max-width: $screen-xs-max){
    section{
        .gallery{
            .gallery-item{
                height:             130px;
                img{
                    min-height:             130px;
                }
            }
            .carousel-control{
                opacity:                1;
                top:                    auto;
                margin-top:             0;
                background-image:       none;

                &.left{
                    margin:                 0 0 0 0;
                    float:                  left;
                }
                &.right{
                    margin:                 0 0 0 0;
                    float:                  right;
                }
            }
        }
    }
}

@media(max-width: 1300px){
    .gallery{
        .carousel-control{
            &.left{
                margin-left:            0 !important;
            }
            &.right{
                margin-right:           0 !important;
            }
        }    
    }
}

@media(max-width: $screen-xs-max){
    &.section-newsletter{
        .validation_error{
            font-size:              14px;
            padding:                $grid-gutter-width/2;
        }
        .validation_message{
            font-size:              14px;
            padding:                $grid-gutter-width/2;
        }

        .gform_body{
            clear: both;
            float:                  none;
            width:              100% !important;
            ul{
                list-style-type:        none;
                padding:                0;
                margin: 0;
            }
        }
        .gform_footer{
            margin: 0;
            clear: both;
            float:                  none;
            width:              100% !important;
            .gform_button.button{
                width: 100%;
                border-left: 2px solid $white;
                border-top: none;
            }
        }
    }
    .mymail-email-wrapper{
        float:                  none;
        clear: both;
        width:                  100% !important;
        input.mymail-email{
            background-image: none !important;
        }
    }
    .mymail-submit-wrapper{
        float:                  none;
        clear: both;
        width:                  100% !important;
        input.submit-button{
            border-top: none !important;
            border-left: 2px solid $white !important;
        }
    }
}