#map{
    width:                  100%;
    height:                 70vh;
}

.contact-info{
    background-color:       $brand-primary;
    color:                  $white;
    padding:                3em 3em 2em 3em;
    position:               absolute;
    bottom:                 0;
    font-size: 18px;
    h1,h2,h3,h4,h5,h6{
        padding:                0;
        margin:                 0 0 0.3em 0;
    }
    h4{
        font-family:            $font-2;
        padding-top:            0.5em;
    }
    p{
        margin:                 0 0 1em 0;
        &:last-child{
            margin: 0;
        }
    }
    a{
        color: $white;
        transition: all 0.2s;
        &:hover{
            transition: all 0.2s;
            opacity: .8;
            text-decoration: none;
        }
    }
}

.footer-menu{
    font-size: 12px;
    ul{
        li{
            padding: 0 20px;
            a{
                padding: 0;
            }
        }
    }
}

.map-section{
    margin:                 0;
    border-bottom:          $grid-gutter-width/2 solid $brand-primary;
}

.content-info{
    padding-bottom: 2em;
    background-color: $brand-primary;
    .inner-padding{
        padding: 3em 0;
        display: block;
        color: $white;
        background-color: #761413;
        .image-control{
            min-height: 100px;
            margin-bottom: $grid-gutter-width;
        }
        .info-block{

            font-size: 16px;
            min-height: 180px;
            padding-top: $grid-gutter-width;
            border-top: 2px solid rgba(255,255,255,0.1);
        }
        a{
            color: $white;
            opacity: 0.7;
            transition: all 0.3s;
            &:hover{
                text-decoration: none;
                transition: all 0.3s;
                opacity: 1;
            }
        }
    }
}

.footer-strong-text{
    color: $white;
    font-weight: 700;
    font-family: $font-1;
    font-size: 42px;
    padding: 1em 0;
}

#footer-modal{
    .modal-content{
        padding: 0;
        color: $font-color;
        .modal-header{
            position: inherit;
            background-color: $brand-primary;
            .modal-title{
                display: inline;
                color: $white;
            }
            .close{
                margin: 0;
                opacity: 0.8;
                &:hover{
                    opacity: 1;
                }
            }
        }
        .modal-body{
            p{
                margin: 0 0 20px 0;
            }
        }
    }
}

.section-newsletter{
    .mymail-form{
        padding: 80px 0 !important;
    }
}

@media(max-width: $screen-xs-max){
    .section-newsletter{
        .mymail-form{
            padding: 0 !important;
        }
    }
    .contact-info{
        padding:                2em;
        position:               relative;
        margin: 0 -$grid-gutter-width/2;
        font-size:              14px;
        /*        h1,h2,h3,h4,h5,h6{
                    padding:                0;
                    margin:                 0 0 0.3em 0;
                }
                h4{
                    font-family:            $font-2;
                    padding-top:            0.5em;
                }
                p{
                    margin:                 0 0 1em 0;
                    &:last-child{
                        margin: 0;
                    }
                }*/
    }
    .footer-strong-text{
        font-size: 20px;
        padding: 1em 0;
    }
}
@media(max-width: $screen-sm-max){
    .section-newsletter{
        .mymail-form{
            padding: 0 !important;
        }
    }
}