header.banner{
    z-index: 1000;

    .main-navigation{
        clear:                  both;
        display:                block;
        margin-top:             $grid-gutter-width/2;
        font-size:              16px;

        .menu-item{
            a{
                background-color:       transparent;
                color:                  $brand-primary;
                transition:             all 0.3s;
                &:before{
                    transition:             all 0.3s;
                }
                &:after{
                    transition:             all 0.3s;
                }
                &:hover{
                    color:                  $brand-primary;
                    transform:              translateZ(10px);
                    transition:             all 0.3s;
                    &:before{
                        transition:             all 0.3s;
                    }
                    &:after{
                        transition:             all 0.3s;
                    }
                }
                &:focus{
                    background-color:       transparent;
                    color:                  $brand-primary;
                    opacity:                0.6;
                    transition:             all 0.3s;
                }
            }
        }

        .menu-btn{
            display:                block;
            width:                  100%;
            color:                  $font-color;
            padding:                0.5em;
            text-decoration:        none;
            background-color:       $white;
            transition:             all 0.3s;
            &:hover{
                background-color:       rgba(255,255,255,0.8);
                transition:             all 0.3s;
            }
        }

        .collapsing{
            transform:              scale3d(1.2,0.5,0.7) rotateX(90deg) perspective(600px);
            transition:             all 0.5s;
        }
        .collapse{
            &.in{
                background-color:       $white;
                transform:              scale3d(1,1,1) rotateX(0);
                color:                  $brand-primary;
                transition:             all 0.5s;
            }
        }
    }
    .carousel-fade .carousel-inner .item {
        opacity: 0;
        -webkit-transition-property: opacity;
        -moz-transition-property: opacity;
        -o-transition-property: opacity;
        transition-property: opacity;
    }
    .carousel-fade .carousel-inner .active {
        opacity: 1;
    }
    .carousel-fade .carousel-inner .active.left,
    .carousel-fade .carousel-inner .active.right {
        left: 0;
        opacity: 0;
        z-index: 1;
    }
    .carousel-fade .carousel-inner .next.left,
    .carousel-fade .carousel-inner .prev.right {
        opacity: 1;
    }
    .carousel-fade .carousel-control {
        z-index: 2;
    }
    .nav-primary{
        position:               fixed;
        top:                    $grid-gutter-width/2;
        left:                   $grid-gutter-width;
        width:                  180px;
        padding-bottom:         $grid-gutter-width/2;
        text-align:             center;
        border:                 none;
        background-color:       transparent;
        border-radius:          0;
        z-index:                10;

        .brand{
            display:                table;
            margin:                 0 auto;
            &:after{
                content: '';
                display: block;
            }
        }

        .navbar-header{
            text-align:             center;
            background-color:       $white;
            width:                  100%;
            margin-bottom:          $grid-gutter-width/2;
            .brand{
                img{
                }
            }
        }
        .navbar-collapse{
            background-color:       $white;
        }

        &:before{
            content:                '';
            position:               fixed;
            top:                    0;
            left:                   0;
            right:                  0;
            height:                 $grid-gutter-width/2;
            background:             $white;
            display:                block;
        }
    }

    .carousel{
        .full-width-image{
            display:                block;
            top:                    0;
            left:                   0;
            right:                  0;
            bottom:                 0;
            width:                  100vw;
            height:                 100vh;
            background-size:        cover;
            background-repeat:      no-repeat;
            background-position:    center;
        }
    }

    .facebook-icon{
        padding:                0.3em 0.5em;
        background-color:       $white;
        color:                  $brand-primary;
        position:               fixed;
        font-size:              2em;
        line-height:            1;
        top:                    $grid-gutter-width/2;
        right:                  $grid-gutter-width;
        transition:             all 0.3s;
        i{
            opacity:                 1;
            transition:             all 0.3s;
        }
        &:hover{
            opacity:                1;
            transition:             all 0.3s;
            i{
                opacity:                0.8;
                transition:             all 0.3s;
            }
        }
    }
}

.lang-changer{
    padding-top:            0.5em;
    ul{
        font-size:              0.6em;
        text-transform:         uppercase;
        li{
            border-right:           1px solid $brand-primary;
            &:last-child{
                border-right:           0;
            }
            a{
                font-weight:            bold;
                color:                  $brand-primary;
                text-decoration:        none;
            }
        }
    }
}

@media(max-width: $screen-sm-max){
    .lang-changer{
        position: absolute;
        right: 75px;
    }
    header.banner{
        .main-navigation{
            margin-top: 0;
        }
        .nav-primary{
            left: 0;
            top: 0;
            width: 100%;
            &:before{
                content:                '';
                position:               fixed;
                top:                    0;
                left:                   0;
                right:                  0;
                height:                 0;
                background:             $white;
                display:                block;
            }
            .facebook-icon{
                top: 0;
                right: 0;
            }
            .navbar-header{
                margin-bottom: 0;
                .brand{
                    float: left;
                    padding: $grid-gutter-width/2;
                    img{
                        max-height: 50px;
                    }
                }
                .lang-changer{
                    float: left;
                }
            }
        }
    }
}

.cookie-confirmation{
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    font-size: 12px;
    opacity: 1;
    display: block;
    z-index: 100;
    box-shadow: 0 20px 20px rgba(0,0,0,0.2);
    background: $brand-primary;
    color: $white;
    -moz-animation-name: lightTransition;
    -moz-animation-iteration-count: 1;
    -moz-animation-timing-function: ease-in;
    -moz-animation-duration: 1s;

    -webkit-animation-name: lightTransition;
    -webkit-animation-iteration-count: 1;
    -webkit-animation-timing-function: ease-in;
    -webkit-animation-duration: 1s;

    animation-name: lightTransition;
    animation-iteration-count: 1;
    animation-timing-function: ease-in;
    animation-duration: 1s;

    @-moz-keyframes lightTransition {
        0% {
            -moz-transform: translateY(-40px);
            opacity: 0;
        }
        50% {
            -moz-transform: translateY(-40px);
            opacity: 0;
        }
        100% {
            -moz-transform: translateY(0);
            opacity: 1;
        }
    }
    @-webkit-keyframes lightTransition {
        0% {
            -webkit-transform: translateY(-40px);
            opacity: 0;
        }
        50% {
            -webkit-transform: translateY(-40px);
            opacity: 0;
        }
        100% {
            -webkit-transform: translateY(0);
            opacity: 1;
        }
    }
    @keyframes lightTransition {
        0% {
            transform: translateY(-40px);
            opacity: 0;
        }
        50% {
            transform: translateY(-40px);
            opacity: 0;
        }
        100% {
            transform: translateY(0);
            opacity: 1;
        }
    }

    .btn{
        border: 2px solid;
        border-radius: 0;
        margin: 5px;
        &.btn-default{
            text-align: center;
            padding: 6px;
            color: $white;
            background: $brand-primary;
            font-weight: normal;
            text-decoration: none;
            font-size: 12px;
            display: block;
            width: 100%;
            transition: all 0.3s;
            &:hover{
                border-color: $white;
                background: $white;
                color: #6F1C75;
                transition: all 0.3s;
            }
        }
    }
}