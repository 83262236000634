body{
    font-family:                $font-2;
}

h1,h2,h3,h4,h5,h6{
    font-family:                $font-1;
    font-weight:                600;
}

p{
    margin:                     $grid-gutter-width 0;
}

.footer-modal{
    color: $font-color;
}

.carousel-fade {
    .carousel-inner {
        .item {
            transition-property: opacity;
        }

        .item,
        .active.left,
        .active.right {
            opacity: 0;
        }

        .active,
        .next.left,
        .prev.right {
            opacity: 1;
        }

        .next,
        .prev,
        .active.left,
        .active.right {
            left: 0;
            transform: translate3d(0, 0, 0);
        }
    }

    .carousel-control {
        z-index: 2;
    }
}

.modal{
    .modal-dialog{
        width:                      800px;
        @media(max-width: $screen-xs-max){
            width: 90%;
        }
        .modal-content{
            border-radius:              0;
            border:                     none;
            box-shadow:                 none;
            .modal-header{
                position:                   absolute;
                border:                     none;
                width:                      100%;
                .close{
                    color:                      $white;
                }
            }
            .brand-logo{
                display:                    table;
                margin:                     4em auto;
                padding:                    4em;
                opacity:                    0;
                -webkit-animation:          loader 1.5s linear;
                animation:                  loader 1.5s linear;
                -webkit-transform:          scale3d(1.5,1.5,1.5);
                transform:                  scale3d(1.5,1.5,1.5);
                @-webkit-keyframes loader {
                    0% {
                        opacity:                    0;
                        -webkit-transform:          scale3d(1,1,1);
                        transform:                  scale3d(1,1,1);
                    }
                    50% {
                        opacity:                    1;
                        -webkit-transform:          scale3d(1.25,1.25,1.25);
                        transform:                  scale3d(1.25,1.25,1.25);
                    }
                    100% {
                        opacity:                    0;
                        -webkit-transform:          scale3d(1.5,1.5,1.5);
                        transform:                  scale3d(1.5,1.5,1.5);
                    }
                }
                @keyframes loader {
                    0% {
                        opacity:                    0;
                        -webkit-transform:          scale3d(1,1,1);
                        transform:                  scale3d(1,1,1);
                    }
                    50% {
                        opacity:                    1;
                        -webkit-transform:          scale3d(1.25,1.25,1.25);
                        transform:                  scale3d(1.25,1.25,1.25);
                    }
                    100% {
                        opacity:                    0;
                        -webkit-transform:          scale3d(1.5,1.5,1.5);
                        transform:                  scale3d(1.5,1.5,1.5);
                    }
                }
            }
            .current-image{
                .gallery-image-lg{
                    opacity:                    1;
                    -webkit-animation:          fancyLoad 0.3s linear;
                    animation:                  fancyLoad 0.3s linear;
                    @-webkit-keyframes fancyLoad {
                        0% {
                            opacity:                    0;
                        }
                        50% {
                            opacity:                    1;
                        }
                    }
                    @keyframes fancyLoad {
                        0% {
                            opacity:                    0;
                        }
                        100% {
                            opacity:                    1;
                        }
                    }
                }
            }
        }
    }
}

.modal-backdrop{
    &.in{
        opacity: 1;
        background-color: rgba(0,0,0,0.8);
    }
}