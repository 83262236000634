// Glyphicons font path
$icon-font-path:        "../fonts/";

// Grid settings
$main-sm-columns:       12;
$sidebar-sm-columns:    4;

// Colors
$brand-primary:         #660404;
$white:                 #ffffff;
$font-color:            #2c2c2c;
$light-gray:            #ece9e9;
$dark-gray:             #332d2d;

// Fonts
$font-1:                'Noto Sans', sans-serif;
$font-2:                'Lora', serif;

$font-size-base:        20px !default;
$text-color:            $font-color;